<template>
	<div class="container">
		<div class="row">
			<span>Datum:</span>
			<date-picker :date="startTime" :option="option" :limit="limit"></date-picker>
		</div>

		<!-- <div class="row">
            {{ getWeatherFromAPI }}
		</div> -->
	</div>
</template>

	<!--Datetime picker-->
	<!--@see https://www.npmjs.com/package/vue-datepicker-->

<script>
    export default {
        name: "date-weather",

        props: [
            'project',
            'address'
        ],
        created() {
            this.address = JSON.parse(this.address);
        },
        data: function () {
            const moment = require('moment');
            let m = moment();
            return {
                startTime: {
                    time: m.format('DD.MM.YYYY')
                },
                endtime: {
                    time: ''
                },

                option: {
                    type: 'day',
                    week: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
                    month: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September',
                        'Oktober', 'November', 'Dezember'],
                    format: 'DD.MM.YYYY',
                    placeholder: 'Baustellentag',
                    inputClass: 'form-control',
                    wrapperClass: '',
                    color: {
                        header: '#2B618E',
                        headerText: '#fff'
                    },
                    buttons: {
                        ok: 'Ok',
                        cancel: 'Abbrechen'
                    },
                    overlayOpacity: 0.5, // 0.5 as default
                    dismissible: true // as true as default
                },
                timeoption: {
                    type: 'min',
                    week: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
                    month: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September',
                        'Oktober', 'November', 'Dezember'],
                    format: 'DD.MM.YYYY HH:mm'
                },
                multiOption: {
                    week: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
                    month: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September',
                        'Oktober', 'November', 'Dezember'],
                    format: 'DD.MM.YYYY HH:mm'
                },
                limit: [{
                    type: 'weekday',
                    available: [1, 2, 3, 4, 5]
                }]
            }
        },
        components: {
            'date-picker': myDatepicker
        },
        computed: {
            getWeatherFromAPI: function() {
                return axios.get('https://api.darksky.net/forecast/619d8e4f10511b8143cf139a10f798ff/37.8267,-122.4233')
                // .then(function (response) {
                //     this.weather = response.data
                //   })
            }
            //   Für das Wetterplugin: Prüfe ob das Datum das Heutige ist (moment)
            //   Wenn ja, dann prüfe ob bereits ein Eintrag zum heutigen Datum und dem Projekt vorhanden ist
        }
    }

    import myDatepicker from '../libs/vue-datepicker'
</script>

<style lang="scss">
	#app * .cov-date-body {
		font-family: "Raleway";
	}
</style>