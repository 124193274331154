<template>
    <div>
        <div class="form-row">
            <div class="form-group col-md-8">
                <label class="has-float-label">
                    <input type="text" :id="this.form_name+'_street_name'" :name="this.form_name+'_street_name'" class="form-control"
                           placeholder="Bau-Gasse" data-parsley-required v-model="street_name">
                    <span>Straße</span>
                </label>
            </div>
            <div class="form-group col-md-2 pl-md-3">
                <label class="has-float-label">
                    <input :id="this.form_name+'_street_number'" type="text" class="form-control" :name="this.form_name+'_street_number'"
                           data-parsley-required v-model="street_number" placeholder="3a-c">

                    <span>Hausnr.</span>
                </label>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-2">
                <label class="has-float-label">
                    <input type="text" :id="this.form_name+'_zip_code'" :name="this.form_name+'_zip_code'" class="form-control"
                           placeholder="01234" data-parsley-required maxlength="5" v-model="zip_code">
                    <span>PLZ</span>
                </label>
            </div>
            <div class="form-group col-md-8 pl-md-3">
                <label class="has-float-label">
                    <input type="text" :id="this.form_name+'_town'" :name="this.form_name+'_town'" class="form-control"
                           placeholder="Bauhausen" data-parsley-required v-model="town">
                    <span>Ort</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AddressForm",

        props:['init-street-name', 'init-street-number', 'init-zip-code', 'init-town', 'init-form-name'],

        data() {
            return {
                // Use computed properties, because props will change on enterprise selection
                zip_code: this.initZipCode,
                town: this.initTown,
                street_name: this.initStreetName,
                street_number: this.initStreetNumber,
                form_name: this.initFormName,
            }
        },

        watch: {
            zip_code: function(newPlz, oldPlz) {
                // this.town = 'Warte auf vollständige Postleitzahl...';
                if(newPlz.length === 5 && oldPlz > 4) {
                    // console.log('OLD: ' + oldPlz + ' NEW ' + newPlz);
                    this.debouncedGetTown();
                }
            }
        },

        created() {
            // _.debounce is a function provided by lodash to limit how
            // often a particularly expensive operation can be run.
            // In this case, we want to limit how often we access
            // yesno.wtf/api, waiting until the user has completely
            // finished typing before making the ajax request. To learn
            // more about the _.debounce function (and its cousin
            // _.throttle), visit: https://lodash.com/docs#debounce
            this.debouncedGetTown = _.debounce(this.getTown, 500)
        },

        computed: {
            street_name: function () {
                return this.initStreetName;
            },
            street_number: function () {
                return this.initStreetNumber;
            },
            zip_code: function () {
                return this.initZipCode;
            },
            town: function () {
                return this.initTown;
            }
        },

        methods: {
            getTown: function () {
                window.axios.get('/api/v1/postcodes/'+this.zip_code).then(({ data }) => {
                    // console.log(data.data[0].town);
                    if (data.data.length > 0) {
                        this.town = data.data[0].town;
                    } else {
                        // this.town = 'Zu dieser PLZ gibt es keinen Ort!'
                    }
                    return;
                });
            }
        }
    }
</script>
