<template>
    <div class="card dropdown-item mb-1">
        <div class="card-header">
            <small>{{ unread.created_at }}</small>
            <button class="close float-right" type="button" aria-label="Close" tabindex="-1" v-on:click.prevent="markNotificationAsRead(unread.id)">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="card-body">
            <h5 class="card-title">Einladung angenommen</h5>
            <p class="card-text">
                <span class="text-info"> {{ unread.data }} </span>
<!--                ist Ihrem <a :href="projectURL"> Projekt #{{ unread.data.project.project_number }}-->
<!--                &lt;!&ndash;{{ unread.data.project.project_name }}&ndash;&gt;</a> beigetreten.-->
            </p>
        </div>
    </div>

</template>

<script>
    export default {
        name: "NotificationItem",
        props:['unreadNotifications', 'unread'],

        data() {
            return {
                projectURL:"",
            };
        },

        methods: {
            markNotificationAsRead(unreadId) {
                Object.assign(axios.defaults.headers, {
                    'X-Requested-With': 'XMLHttpRequest',
                });
                axios.post("/markasread/"+unreadId).then(( {result}) => {
                    this.unreadNotifications.splice(unreadId, 1);
                }).catch(err => {
                    console.log(err);
                });
            },
        },

        mounted() {
            // this.projectURL="/projects/"+this.unread.data.project.id;
            console.log(this.unreadNotifications);
        }
    }
</script>