<template>
    <!-- Taken from https://codepen.io/anon/pen/LobZwE -->

    <div>
        <h2 class="card-title">Unternehmensdaten</h2>
        <div class="form-row">
            <div class="form-group col-md-5">
                <div class="input-group">
                    <label class="has-float-label">
                        <input type="text" class="form-control"
                               aria-label="Firmenname eintragen um bereits gelistete Firmen zu finden"
                               id="enterprise_name" name="enterprise_name"
                               placeholder="Bauloewe GmbH"
                               @input="onInput($event.target.value)"
                               @blur="isOpened = false"
                               @keyup.enter="select"
                               @keyup.tab="select"
                               @keydown.down="onDown"
                               @keydown.up="onUp"
                               @keyup.esc="isOpened = false"
                               ref="dropdown"
                               data-parsley-required
                               v-model="enterprise_name"
                        />
                        <span>Unternehmensname</span>
                    </label>

                    <div class="input-group-append">
                        <ul class="dropdown-menu" :class="show">
                            <h5 class="m-1 d-flex">Gefundene Firmen</h5>
                            <li v-for="(option, i) in filteredItems"
                                @mouseenter="selected = i"
                                @mousedown="select"
                                :class="{'selected': i === selected}"
                                class="dropdown-item"
                            >
                                {{ option.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
<!--            <div class="form-group col-md-5">-->
<!--                <label class="has-float-label">-->
<!--                    <input type="text" class="input-dropdown form-control" id="enterprise_name" name="enterprise_name"-->
<!--                           placeholder="Bauloewe GmbH"-->
<!--                           @input="onInput($event.target.value)"-->
<!--                           @blur="isOpened = false"-->
<!--                           @keyup.enter="select"-->
<!--                           @keyup.tab="select"-->
<!--                           @keydown.down="onDown"-->
<!--                           @keydown.up="onUp"-->
<!--                           @keyup.esc="isOpen = false"-->
<!--                           ref="dropdown"-->
<!--                           data-parsley-required-->
<!--                           v-model="enterprise_name"-->
<!--                    />-->
<!--                    <i class="fas fa-chevron-down"-->
<!--                       @click="toggle"-->
<!--                       :class="{'chevron-active' : isOpened, 'chevron-inactive' : !isOpened}"></i>-->

<!--                    <span>Unternehmensname</span>-->
<!--                </label>-->

<!--                <ul class="options-list shadow" v-show="isOpened">-->
<!--                    <h5 class="m-1 d-flex">Gefundene Firmen</h5>-->
<!--                    <li v-for="(option, i) in filteredItems"-->
<!--                        @mouseenter="selected = i"-->
<!--                        @mousedown="select"-->
<!--                        :class="{'selected': i === selected}">-->
<!--                        {{ option.name }}-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
        </div>

        <address-form
                :init-street-name="street_name"
                :init-street-number="street_number"
                :init-zip-code="zip_code"
                :init-town="town"
                init-form-name="enterprise_address_form"
        ></address-form>
    </div>
</template>

<script>
    import AddressForm from './AddressForm.vue';

    export default {
        name:"EnterpriseDataForm",
        components: {AddressForm},

        data() {
            return {
                enterprise_name: '',
                isOpened: false,
                show: '',
                selected: null,
                // search: "",
                options: [],

                street_name:'',
                street_number:'',
                zip_code:'',
                town:''
            };
        },

        props: {
            'init-enterprise-name': {},
            'init-street-name': {},
            'init-street-number': {},
            'init-zip-code': {},
            'init-town': {},
        },

        created() {
            // Call method to catch all enterprises from db first
            this.getEnterprises();
            // assign props to data
            this.enterprise_name = this.initEnterpriseName;
            this.street_name = this.initStreetName;
            this.street_number = this.initStreetNumber;
            this.zip_code = this.initZipCode;
            this.town = this.initTown;
        },

        computed: {
            filteredItems() {
                // console.log('Filtered items called');
                const condition = new RegExp(this.enterprise_name, "i");
                return this.options.filter(item => item.name.match(condition));
            },
        },

        methods: {
            getEnterprises() {
                // console.log('getEnterprises called');
                window.axios.get('/api/v1/enterprises/').then(({ data }) => {
                    this.options = data.data;
                });
            },
            onInput(value) {
                this.isOpened = !!value; //(value != '');
                this.show = 'show';
                this.selected = null;
            },
            select() {
                const selectedOption = this.filteredItems[this.selected];
                this.$emit("select-item", selectedOption);
                this.enterprise_name = selectedOption.name;
                this.isOpened = false;
                this.show = '';

                this.street_name = selectedOption.address.street_name;
                this.street_number = selectedOption.address.street_number;
                this.zip_code = selectedOption.address.zip_code
                this.town = selectedOption.address.town;
            },
            onDown() {
                if (!this.isOpened || this.show ==='') {
                    return;
                }
                this.selected = (this.selected + 1) % this.filteredItems.length;
            },
            onUp() {
                if (!this.isOpened || this.show ==='') {
                    return;
                }

                this.selected =
                    this.selected - 1 < 0
                        ? this.filteredItems.length - 1
                        : this.selected - 1;
            },
            toggle() {
                this.isOpened = !this.isOpened;
                this.show = 'show';

                if (this.isOpened) {
                    //this.$refs.dropdown.focus();
                }
            },
            onOptionSelect(option) {
                console.log(option);
            },
        },
    }
</script>
