<template>
    <div class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle"
           id="dropdownNotificationsButton"
           data-toggle="dropdown"
           role="button"
           aria-haspopup="true"
           aria-expanded="false">

            <span class="fa-layers fa-fw">
                <i class="fas fa-bell"></i>

                <span class="fa-layers-counter fa-2x"  v-if="unreadNotifications.length > 0"></span>
            </span>

        </a>

        <div class="dropdown-menu dropdown-menu-right" id="notifications-dropdown" aria-labelledby="dropdownNotificationsButton">
            <notification-item v-for="unread in unreadNotifications" :unread="unread" :unreadNotifications="unreadNotifications"
                               :key="unread.id"></notification-item>
            <p class="list-group-item text-muted" v-if="unreadNotifications.length === 0">Keine neuen Benachrichtigungen</p>
        </div>
    </div>
</template>

<script>
    import NotificationItem from './NotificationItem.vue';

    export default {
        name: "Notification",
        props: ['user', 'userid', 'unread'],
        components: {NotificationItem},
        data() {
            return {
                unreadNotifications: this.unread,
                parentProjectUrl: "",
            };
        },

        methods: {
            listen() {
                console.log('Listening...');
                console.log('Listening on ' + 'App.User.'+this.user.id);

                // console.log(this.unreadNotifications);

                // Echo.channel('subcontractor-invited-'+this.user.id)
                //     .listen('.subcontractor.accepted.invitation', (e) => {
                //         console.log('Delivered by pusher'+e);
                //     });
                // Echo.private('invitedRegisteredUser-'+this.userid).listen('.user.invited', (e) => {
                //    console.log(e);
                // });

                Echo.private('App.User.'+this.user.id).notification((notification) => {
                    console.log('Notification' + notification);
                    // let newUnreadNotifications = {data: {project: notification.project, user: notification.user}};
                    let newUnreadNotifications = {data: notification};
                    this.unreadNotifications.push(newUnreadNotifications);
                    console.log('New unread notifications to pusher: ' + newUnreadNotifications);
                });
            },
        },
        mounted() {
            // console.log('Notifications component mounted.');
            this.listen();
        }
    }
</script>