/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import bsCustomFileInput from "bs-custom-file-input";


require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('enterprise-data-form', require('./components/EnterpriseDataForm.vue').default);
Vue.component('hirings-form', require('./components/HiringsForm.vue').default);
Vue.component('date-weather', require('./components/DateWeather.vue').default);
Vue.component('notification', require('./components/Notification.vue').default);
Vue.component('address-form', require('./components/AddressForm.vue').default);
// Vue.component('inhouse-contributors', require('./components/enterprise/project/InhouseContributors.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});


/**
 * Toogle popper, tooltips and toast (bootstrap), bs-custom-file-input
 */
$(document).ready(function() {
    $('[data-toggle="tooltip"]').tooltip();
    $('.toast').toast('show');
    $('[data-toggle="popover"]').popover();

    bsCustomFileInput.init();
});

/**
 * Animated hero header
 */
var TxtRotate = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtRotate.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

    var that = this;
    var delta = 300 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function() {
        that.tick();
    }, delta);
};

window.onload = function() {
    var elements = document.getElementsByClassName('txt-rotate');
    for (var i=0; i<elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-rotate');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtRotate(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
};


/**
 * Toggle for sidebar(s)
 */
var hamburger = document.querySelector("#sidebarCollapse");

// Check if the sidebar is toggled
if(!document.getElementById('sidebar-wrapper').clientWidth === 0) {
    $("#sidebar-wrapper").toggleClass("toggled");
    $("#page-content-wrapper").toggleClass("sidebar-wrapper-toggled");
    $(".footer").toggleClass("sidebar-wrapper-toggled");

    hamburger.addEventListener("click", function() {
        // Toggle class "active"
        hamburger.classList.toggle("active");
        // Do something else, like open/close menu
        $("#sidebar-wrapper").toggleClass("toggled");
        $("#page-content-wrapper").toggleClass("sidebar-wrapper-toggled");
        $(".footer").toggleClass("sidebar-wrapper-toggled");
    });
} else {
    // On click
    // Toggle class "active"
    hamburger.classList.toggle("active");
    $("#sidebar-wrapper").toggleClass("toggled");
    $("#page-content-wrapper").toggleClass("sidebar-wrapper-toggled");
    $(".footer").toggleClass("sidebar-wrapper-toggled");
    hamburger.addEventListener("click", function() {
        // Toggle class "active"
        hamburger.classList.toggle("active");
        // Do something else, like open/close menu
        $("#sidebar-wrapper").toggleClass("toggled");
        $("#page-content-wrapper").toggleClass("sidebar-wrapper-toggled");
        $(".footer").toggleClass("sidebar-wrapper-toggled");
    });
}

/*
 * Delete stuff from DB without the need of an form. Just with the magic of jquery.
 * Taken from laravel.js
 * @see https://gist.github.com/soufianeEL/3f8483f0f3dc9e3ec5d9
 * Exemples :
 * <a href="posts/2" data-method="delete" data-token="{{csrf_token()}}">
 * - Or, request confirmation in the process -
 * <a href="posts/2" data-method="delete" data-token="{{csrf_token()}}" data-confirm="Are you sure?">
 */
(function() {
    var laravel = {
        initialize: function() {
            this.methodLinks = $('a[data-method]');
            this.token = $('a[data-token]');
            this.registerEvents();
        },

        registerEvents: function() {
            this.methodLinks.on('click', this.handleMethod);
        },

        handleMethod: function(e) {
            e.preventDefault();

            var link = $(this);
            var httpMethod = link.data('method').toUpperCase();
            var form;

            // If the data-method attribute is not PUT or DELETE,
            // then we don't know what to do. Just ignore.
            if ( $.inArray(httpMethod, ['PUT', 'DELETE']) === - 1 ) {
                return;
            }

            // Allow user to optionally provide data-confirm="Are you sure?"
            // if ( link.data('confirm') ) {
            //   if ( ! laravel.verifyConfirm(link) ) {
            //     return false;
            //   }
            // }
            laravel.verifyConfirm(link).done(function() {
                form = laravel.createForm(link);
                form.submit();
            })

            // form = laravel.createForm(link);
            // form.submit();
        },

        verifyConfirm: function(link) {
            var confirm = new $.Deferred();
            // var title = "Some Text"
            // var userResponse = window.confirm(link.data('confirm'))
            swal({
                title: "Löschen bestätigen",
                text: "Diese Aktion kann nicht rückgängig gemacht werden.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ja, löschen!',
                cancelButtonText: 'Abbruch',
                timer: 5000
            }).then(function () {
                confirm.resolve(link);
                swal(
                    'Gelöscht!',
                    'Und weg damit!',
                    'success'
                )
            }, function (dismiss) {
                // dismiss can be 'cancel', 'overlay',
                // 'close', and 'timer'
                if (dismiss === 'timer') {
                    confirm.reject(link);
                    swal(
                        'Puh, das war knapp!',
                        'Es wurde nichts geändert. Früher war eh alles besser. :)',
                        'error'
                    )
                }
            })

            // if (userResponse) {
            //     confirm.resolve(link);
            // } else {
            //     confirm.reject(link);
            // }
            return confirm.promise();
            // return confirm(link.data('confirm'));
        },

        createForm: function(link) {
            var form =
                $('<form>', {
                    'method': 'POST',
                    'action': link.attr('href')
                });

            var token =
                $('<input>', {
                    'type': 'hidden',
                    'name': '_token',
                    'value': link.data('token')
                });

            var hiddenInput =
                $('<input>', {
                    'name': '_method',
                    'type': 'hidden',
                    'value': link.data('method')
                });

            return form.append(token, hiddenInput)
            // .appendTo('body');
                .appendTo('#app');
        }
    };

    laravel.initialize();

})();
