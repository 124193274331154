<template xmlns:>
    <div class="container">
        <div class="row">
            <div class="col">
                <table class="table table-responsive-sm table-striped">
                    <thead>
                        <tr>
                            <td>
                                Anstellung
                            </td>
                            <td>
                                Name
                            </td>
                            <td>
                                Von
                            </td>
                            <td>
                                Bis
                            </td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(wrkr, index) in hiringsList">
                            <td>
                                <!--<select class="form-control" id="workerJob" name="workerJob[]" v-model="wrkr.employment.id">-->
                                <select class="form-control" id="workerJob" :name="'workers[' + index + '][jobId]'" v-model="wrkr.job_id">
                                    <option :value="job.id"
                                            v-for="job in jobs">{{job.name}}
                                    </option>
                                </select>
                            </td>
                            <td>

                                <!--<input id="workerId" type="hidden" name="workerId[]" v-bind:value="wrkr.id">-->
                                <input id="workerId" type="hidden" :name="'workers[' + index + '][workerId]'" v-model="wrkr.id">
                                <input id="workerName" type="text" class="form-control" :name="'workers[' + index + '][name]'" v-model="wrkr.worker_name">
                            </td>
                            <td><input id="workedFrom" type="time" class="form-control" :name="'workers[' + index + '][start_time]'" v-model="wrkr.started_at"></td>
                            <td><input id="workedTill" type="time" class="form-control" :name="'workers[' + index + '][end_time]'" v-model="wrkr.ended_at"></td>
                            <td><button class="btn btn-outline-danger w-100" @click.prevent='removeElement(index)'><i class="fas fa-trash"></i></button></td>
                        </tr>
                        <tr class="table-dark">
                            <td>
                                <select class="form-control" id="NewWorkerJob" name="newWorkerJob[]" v-model="newJobId">
                                    <option :value="job.id"
                                            v-for="job in jobs"> {{job.name}}
                                    </option>
                                </select>
                            </td>
                            <td>
                                <!--<input id="newWorkerId" type="hidden" name="workerId[]" >-->
                                <input id="newWorkerName" type="text" class="form-control" name="newWorkerName[]" v-model="newName">
                            </td>
                            <td><input id="newWorkedFrom" type="time" class="form-control" name="newStart_time[]" v-model="newStart_time"></td>
                            <td><input id="newWorkedTill" type="time" class="form-control" name="newEnd_time[]" v-model="newEnd_time"></td>
                            <td><button class="btn btn-primary w-100" @click.prevent='addName()'><i class="fas fa-plus"></i></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'hirings-form',
        props: [
            'jobs',
            'hirings',
        ],
        data() {
            return {
                // Working example
                newName: '',
                newStart_time: '',
                newEnd_time: '',
                newJobId: '',
                hiringsList: this.hirings,
                // the workerId (wid) is just to differ the already in db safed objects from the newer ones with even that wid.
                wid: parseInt(new Date().getTime() / 1000),
                // worker: [],
                selected: 2,
            }
        },

        methods: {
            addName: function() {
              // this.hirings.push({employment_id: this.newJobId, name: this.newName, start_time: this.newStart_time, end_time: this.newEnd_time});
              this.hiringsList.push({id: this.wid, job_id: this.newJobId, worker_name: this.newName, started_at: this.newStart_time, ended_at: this.newEnd_time});

              this.newName = '';
              this.newStart_time = '';
              this.newEnd_time = '';
              this.newJobId = '';
              console.log(this.hiringsList);
            },
            removeElement: function (index) {
                console.log(index + " Deleted");
                //this.hiringsList.pop(index);
                this.hiringsList.splice(index, 1);
                console.log(this.hiringsList);
            }
        }
    }
</script>
